"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
/**
 * A hook that returns the users scroll direction.
 * @returns {string} - The scroll direction of the page, either 'down' or 'up'.
 */
var useScrollDirection = function useScrollDirection() {
  var _useState = (0, _react.useState)(null),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    scrollDirection = _useState2[0],
    setScrollDirection = _useState2[1];
  (0, _react.useEffect)(function () {
    var lastScrollY = window.pageYOffset;
    /**
     * compares the page offset to the previous page position on the to see if the page has been scrolled.
     * if the new is larged that the last scroll  it will return down and vice versa
     */
    var updateScrollDirection = function updateScrollDirection() {
      var scrollY = window.pageYOffset;
      var direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection && (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)) {
        setScrollDirection(direction);
      }
      if (scrollY === 0) {
        setScrollDirection('top');
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return function () {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);
  return scrollDirection;
};
var _default = exports["default"] = useScrollDirection;